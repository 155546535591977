import classNames from "classnames"
import * as React from "react"
import { CloseIcon } from "../atoms/Icons"
import Logo from "../atoms/Logo"
import Link from "../atoms/Link"

import * as styles from './styles.module.scss'

interface IProps {
  open: boolean
  close: () => void
}

const MobileMenu = ({ open, close }: IProps) => {
  return (
    <div className={classNames(styles.mobileMenu, { [styles.open]: open })}>
      <div className={styles.header}>
        <Logo />
        <div className={styles.controls}>
          <button className={styles.close} onClick={close}><CloseIcon /></button>
        </div>
      </div>
      <div className={styles.content}>
        <Link 
          to="#directions" 
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          Направления
        </Link>
        <Link 
          to="#structure" 
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          Структура
        </Link>
        <Link 
          to="#ourProjects" 
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          Наши проекты
        </Link>
        <Link 
          to="#contacts" 
          onClick={() => {
            close()
          }}
          className={styles.link}
        >
          Контакты
        </Link>
      </div>
    </div>
  )
}

export default MobileMenu