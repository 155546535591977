import * as React from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'
import NewCarousel from '../components/NewCarousel'

import 'swiper/css';
import '../styles/globals.scss'
import * as styles from './styles.module.scss'
import FirstBlockImage from '../components/atoms/FirstBlockImage';
import StructureCard from '../components/StructureCard';
import { BackendIcon, DevopsIcon, FrontendIcon, MarketIcon, ProductTeamIcon, QAIcon } from '../components/atoms/Icons';
import classNames from "classnames";

const structureColumns = [
  {
    id: 1,
    cards: [
      {
        icon: <FrontendIcon />,
        title: "Frontend",
        text: "Разработка интерфейса ПО. Для решения frontend-задач мы применяем библиотеку React JS в связке с UI Kit, также используем TypeScript"
      },
      {
        icon: <QAIcon />,
        title: "QA",
        text: "Тестировщики, обеспечивающие качество ПО. Для контроля качества мы пользуемся подходами, как ручного, так и автоматического тестирования. Автотесты мы пишем на языке Python."
      }
    ]
  },
  {
    id: 2,
    cards: [
      {
        icon: <BackendIcon />,
        title: "Backend",
        text: "Разработка серверной части ПО. Пишем backend на Python, PHP и Laravel."
      },
      {
        icon: <ProductTeamIcon />,
        title: "Product team",
        text: "Business analyst / System analyst / Project manager — поиск ключевых точек роста бизнеса, достижение поставленных целей за счет применения эффективных методологий, анализ успешности и стабильности работы продукта, составление понятной документации, с целью повышения скорости внедрения новых функций в продукты, применение гибких практик Agile-менеджмента, чтобы разработка продукта шла в срок."
      }
    ]
  },
  {
    id: 3,
    cards: [
      {
        icon: <DevopsIcon />,
        title: "Devops",
        text: "Используем Kubernetes и Gitlab для поднятия сервисов, а также связку Google Cloud и Cloud SQL"
      },
      {
        icon: <MarketIcon />,
        title: "Маркетинг",
        text: "Marketing analyst / Affiliate manager осуществляет сбор и анализ информации о рынке, конкурентах и поведении людей, оценивает маркетинговые стратегии и рекламные кампании, коммуникация с целевыми клиентами, поиск целевых клиентов."
      }
    ]
  }
]

const columnWithNoCopyClass = classNames(styles.column, styles.noCopy)
const IndexPage = () => {
  return (
    <main>
      <title>АЙТИКО</title>
      <meta
        name="description"
        content="АЙТИКО | Крупнейший системный интегратор в России"
      />
      <meta property="og:url" content="https://aytiko-kazan.ru" />
      <meta property="og:title" content="АЙТИКО" />
      <meta property="og:description" content="АЙТИКО | Крупнейший системный интегратор в России" />
      <meta property="og:type" content="website" />

      <link rel="canonical" href="https://aytiko-kazan.ru" />

      <div className={styles.firstBlock}>
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
            <div className={styles.texts}>
              <h1>
                Крупнейший системный интегратор в России
              </h1>
              <div className={styles.description}>
                Мы предоставляем комплексные решения в области информационных технологий и услуги по консалтингу, 
                проектированию, внедрению, гарантийному и сервисному обслуживанию информационных систем любого масштаба.
              </div>
            </div>
            <FirstBlockImage />
          </div>
        </div>
      </div>

      <div className={styles.directionsBlock} id="directions">
        <div className={styles.texts}>
          <h2>Наши направления</h2>
          <div className={styles.description}>
            Мы работаем в сфере электронной коммерции, маркетинговых коммуникаций и масштабирования IT-бизнеса
          </div>
        </div>
        <NewCarousel />
      </div>

      <div className={styles.structureBlock} id="structure">
        <div className={styles.container}>
          <h2>Структура деятельности</h2>

          <div className={styles.content}>
            {structureColumns.map(({id, cards}) => (
              <div className={styles.column} key={id}>
                {cards.map((card) => (<StructureCard { ...card } key={card.title} />))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.ourProjectsBlock} id="ourProjects">
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <h2>Наши проекты</h2>
              <div className={styles.description}>
                Благодаря удобной структуре работы пользовательского интерфейса, наши продукты наиболее доступны для пользователей.
              </div>

              <div className={styles.bigCards}>
                <div className={styles.card}>
                  <div className={columnWithNoCopyClass} data-nosnippet>
                    <noindex>
                    <div className={styles.cardName}>Alanbase</div>
                    <div className={styles.cardText}>
                      <div >
                        Программа для ЭВМ «Alanbase» (правообладатель ООО «АЙТИ КО» ) дата гос. регистрации в Реестре программ для 
                        ЭВМ – 31.08.2022 года, свидетельство о гос.регистрации программы для ЭВМ №2022666367
                      </div>
                    </div>
                    </noindex>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.column}>
                    <div className={styles.cardName}>Создание программ для ЭВМ</div>
                    <div className={styles.cardText}>
                      <div>
                        Программа, обеспечивающая взаимодействие между рекламодателями и партнерами, которые являются владельцами 
                        ресурса с постоянной аудиторией, например, блога, мобильного приложения или веб-сайта.
                      </div>
                      <div>
                        Компания беспокоится о сохранности своих идей и предполагает наличие non-disclosure agreements (NDA).
                      </div>
                    </div>
                  </div>
                  <div className={styles.column}>
                    <div className={styles.cardText}>
                      <div>
                        (Вся информация, связанная с деятельностью в рамках Договоров, а также информация, полученная одной Стороной 
                        от другой в результате взаимодействия по Договорам, считается строго конфиденциальной как в течение срока действия 
                        Договора, так и в течение 5 (Пяти) лет после окончания срока его действия).
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.lowCards}>
                <div className={styles.cardsColumn}>
                  <div className={styles.card}>
                    <div className={styles.column}>
                      <div className={styles.cardName}>
                        Услуги по размещению рекламы, информационных материалов
                      </div>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.column}>
                      <div className={styles.cardName}>
                        Разработка и продвижение web-сайтов
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.cardsColumn}>
                  <div className={styles.card}>
                    <div className={styles.column}>
                      <div className={styles.cardName}>
                        Услуги по продвижению интернет-магазинов
                      </div>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.column}>
                      <div className={styles.cardName}>
                        Услуги по внедрению CRM
                      </div>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.column}>
                      <div className={styles.cardName}>
                        Услуги по веб-дизайну, графическому дизайну
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contactsBlock} id="contacts">
        <div className={styles.container}>
          <Footer />
        </div>
      </div>
    </main>
  )
}

export default IndexPage