import * as React from 'react'

import * as styles from './styles.module.scss'

const FirstBlockImage = () => {
  return (
    <svg className={styles.image} width="457" height="457" viewBox="0 0 457 457" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M228.5 457C354.697 457 457 354.697 457 228.5C457 102.303 354.697 0 228.5 0C102.303 0 0 102.303 0 228.5C0 354.697 102.303 457 228.5 457Z" fill="#4895EF"/>
      <path d="M97.2586 230.867L94.2444 226.964C95.8776 225.146 97.341 223.218 98.6371 221.203L103.197 223.073L109.533 207.633L104.963 205.757C105.457 203.392 105.764 200.99 105.884 198.578L110.779 197.916L108.546 181.377L103.662 182.036C102.915 179.761 101.983 177.526 100.865 175.353L104.768 172.339L94.564 159.133L90.6608 162.148C88.8428 160.514 86.9149 159.051 84.8996 157.755L86.7701 153.195L71.3295 146.859L69.454 151.429C67.0891 150.935 64.6867 150.628 62.2744 150.508L61.6126 145.613L45.0732 147.846L45.7325 152.73C43.4575 153.477 41.2224 154.409 39.0498 155.527L36.0356 151.624L22.83 161.828L25.8442 165.731C24.211 167.549 22.7476 169.477 21.4515 171.492L16.8915 169.622L10.556 185.063L15.126 186.938C14.6315 189.303 14.3243 191.705 14.2045 194.118L9.30981 194.779L11.5424 211.319L16.427 210.66C17.1737 212.935 18.1052 215.17 19.224 217.342L15.3207 220.356L25.5246 233.562L29.4278 230.548C31.2458 232.181 33.1737 233.644 35.189 234.94L33.3185 239.5L48.7592 245.836L50.6346 241.266C52.9995 241.76 55.4019 242.068 57.8142 242.188L58.476 247.082L75.0154 244.85L74.3561 239.965C76.6312 239.218 78.8662 238.287 81.0388 237.168L84.053 241.071L97.2586 230.867ZM76.2291 218.776C64.4869 227.264 47.945 225.141 38.7251 213.966C28.5463 201.624 30.8962 183.294 43.862 173.922C55.6042 165.434 72.1461 167.557 81.366 178.734C91.5448 191.076 89.1949 209.406 76.2291 218.778V218.776Z" fill="white"/>
      <path d="M60.0667 233.422C48.849 233.422 37.9109 228.315 30.7188 219.008C18.225 202.838 21.2167 179.519 37.3865 167.025C44.4513 161.566 53.2467 158.841 62.1469 159.351C70.9598 159.855 79.3082 163.479 85.6487 169.555C85.8685 169.764 85.876 170.114 85.6662 170.334C85.4564 170.553 85.1068 170.561 84.8871 170.351C72.161 158.159 52.028 157.103 38.0583 167.896C22.368 180.018 19.4661 202.643 31.5879 218.334C41.6044 231.297 59.1078 235.862 74.1513 229.434C74.431 229.314 74.7556 229.444 74.873 229.724C74.9929 230.003 74.863 230.328 74.5833 230.445C69.881 232.456 64.9439 233.422 60.0642 233.422H60.0667Z" fill="#F2E6DC"/>
      <path d="M391.027 150.573L374.313 212.982L358.987 142.956L391.027 150.573Z" fill="#363E44"/>
      <path d="M400.599 108.419L428.274 120.506C436.964 124.301 447.181 121.205 452.163 113.269C456.893 105.734 455.542 95.9775 448.929 89.9541C442.019 83.661 433.601 76.2466 428.756 72.8828C419.521 66.4723 400.599 108.419 400.599 108.419Z" fill="#F45C27"/>
      <path d="M285.52 60.6587C285.52 60.6587 296.326 38.7476 338.567 39.7266L340.138 60.6587H285.518H285.52Z" fill="#F45C27"/>
      <path d="M338.567 39.7265C338.567 39.7265 396.331 46.7463 429.915 73.7968L403.778 110.756L390.912 153.996C387.299 155 357.828 152.918 352.135 153.762C352.135 153.762 351.146 153.964 340.989 153.824C328.835 153.657 336.632 141.395 308.854 130.5C316.102 113.059 323.346 95.6179 330.593 78.177L338.567 39.724V39.7265Z" fill="#F45C27"/>
      <path d="M396.579 6.93741C395.967 6.53785 390.048 2.79694 384.112 5.07445C379.695 6.77009 377.25 10.6334 374.126 17.7331C370.447 26.094 369.418 31.6054 370.909 34.4173C373.264 38.8575 387.216 38.9848 395.043 34.1002C400.694 30.5741 403.783 23.8414 403.261 17.1562C402.739 10.4885 396.968 7.19213 396.579 6.93741Z" fill="#E2A18D"/>
      <path d="M377.567 61.64C382.906 63.6129 388.832 60.8859 390.805 55.5467L401.688 26.1139C403.661 20.7748 400.934 14.8487 395.595 12.8759C390.255 10.9031 384.329 13.6301 382.357 18.9692L371.473 48.402C369.501 53.7412 372.228 59.6672 377.567 61.64Z" fill="#E2A18D"/>
      <path d="M380.728 38.5703C378.733 38.5703 376.885 38.373 375.349 38.0059C375.055 37.936 374.87 37.6388 374.942 37.3416C375.012 37.047 375.309 36.8647 375.606 36.9346C380.648 38.1408 389.152 37.3342 394.656 33.8979C394.913 33.7381 395.253 33.8155 395.415 34.0727C395.575 34.3299 395.497 34.6696 395.24 34.8319C391.042 37.4515 385.471 38.5703 380.731 38.5703H380.728Z" fill="#363E44"/>
      <path d="M388.652 20.9046C388.652 20.9046 386.674 22.8875 383.833 23.739C383.236 23.9188 382.914 18.747 383.56 18.8444C386.45 19.2814 388.652 20.9046 388.652 20.9046Z" fill="white"/>
      <path d="M374.812 21.956C374.812 21.956 378.114 24.3883 381.934 24.0986C382.604 24.0487 383.241 23.9163 383.842 23.734C384.637 22.1208 384.487 20.2778 383.585 18.8494C382.931 18.7495 382.239 18.7095 381.53 18.762C377.709 19.0517 374.812 21.956 374.812 21.956Z" fill="#363E44"/>
      <path d="M376.201 29.268C375.412 29.268 374.633 28.8634 374.161 28.1642C373.991 27.9119 374.056 27.5698 374.308 27.4C374.56 27.2302 374.902 27.2976 375.072 27.5473C375.412 28.0518 376.024 28.284 376.556 28.1117C377.215 27.8995 377.397 27.1628 377.405 27.1303C377.475 26.8356 377.769 26.6508 378.064 26.7183C378.359 26.7857 378.543 27.0779 378.478 27.375C378.364 27.887 377.899 28.8384 376.893 29.1606C376.665 29.233 376.431 29.268 376.198 29.268H376.201Z" fill="#363E44"/>
      <path d="M373.549 20.6774C373.366 21.0819 373.042 21.6488 372.455 22.1058C371.748 22.6577 371.186 22.6402 370.959 23.1047C370.807 23.4144 370.792 23.9638 371.526 25.0076L373.549 20.6799V20.6774Z" fill="white"/>
      <path d="M371.526 25.557C371.354 25.557 371.181 25.4746 371.074 25.3223C370.352 24.2984 370.152 23.4918 370.464 22.86C370.677 22.428 371.039 22.2581 371.388 22.0958C371.618 21.9884 371.855 21.8761 372.118 21.6713C372.507 21.3666 372.819 20.9571 373.047 20.4501C373.172 20.1729 373.499 20.0481 373.773 20.1729C374.051 20.2978 374.175 20.6224 374.051 20.8996C373.748 21.5714 373.326 22.1233 372.794 22.5378C372.435 22.82 372.113 22.9699 371.855 23.0897C371.598 23.2096 371.491 23.267 371.453 23.3445C371.368 23.5143 371.463 23.9563 371.975 24.6855C372.15 24.9352 372.09 25.2773 371.843 25.4521C371.746 25.5196 371.636 25.552 371.526 25.552V25.557Z" fill="#363E44"/>
      <path d="M391.976 32.6618C390.932 32.6618 389.923 32.3946 389.062 31.8477C388.805 31.6853 388.73 31.3432 388.892 31.0885C389.054 30.8313 389.394 30.7563 389.651 30.9187C391.287 31.9575 393.614 31.7278 395.44 30.3493C396.564 29.5027 397.338 28.344 397.625 27.0829C397.905 25.8567 397.682 24.683 396.998 23.779C395.909 22.3356 393.829 21.8636 391.816 22.6078C391.532 22.7126 391.215 22.5678 391.11 22.2831C391.005 21.9984 391.15 21.6813 391.434 21.5764C393.904 20.6649 396.494 21.2817 397.877 23.1172C398.756 24.2859 399.048 25.7818 398.696 27.3276C398.352 28.8409 397.43 30.2269 396.099 31.2283C394.841 32.1748 393.375 32.6618 391.969 32.6618H391.976Z" fill="#363E44"/>
      <path d="M393.837 29.7425C393.637 29.7425 393.444 29.6326 393.347 29.4428C393.207 29.1731 393.315 28.8409 393.584 28.7011C393.729 28.6262 393.871 28.5363 394.004 28.4364C395.048 27.6497 395.38 26.3212 394.743 25.4771C394.596 25.2798 394.403 25.1225 394.176 25.0101C393.904 24.8753 393.794 24.5431 393.929 24.2709C394.064 23.9987 394.393 23.8889 394.668 24.0237C395.05 24.2135 395.372 24.4807 395.625 24.8153C396.626 26.1464 396.199 28.1642 394.668 29.3179C394.483 29.4553 394.289 29.5776 394.089 29.6825C394.009 29.725 393.921 29.7425 393.837 29.7425Z" fill="#363E44"/>
      <path d="M380.973 4.81473C385.458 3.14406 388.929 3.78586 390.648 3.89324C389.084 3.37131 387.201 2.86936 385.041 2.53972C384.382 2.43983 383.74 2.36242 383.121 2.30248C383.838 1.99532 384.599 1.72561 385.411 1.51334C387.713 0.906508 390.278 0.514437 392.73 1.05884C397.031 2.0128 400.157 6.10582 401.248 7.28952C400.739 6.27563 400.055 5.12689 399.136 3.95068C398.641 3.31887 398.132 2.74699 397.62 2.23006C399.95 2.94428 401.985 3.87576 403.414 5.48899C404.05 6.20821 404.417 6.9499 404.842 7.99126C405.316 9.15249 405.476 10.5285 405.621 11.4575C405.674 11.0879 405.739 10.551 405.763 9.89667C405.773 9.62947 405.793 9.12252 405.781 8.53067C406.303 9.36475 406.735 10.2638 407.057 11.2277C409.407 18.2825 406.016 23.2546 402.068 25.4122C395.445 21.8511 389.646 17.1238 383.59 13.8074C385.231 13.9298 386.557 13.6501 387.304 13.4378C383.373 12.9009 381.178 12.3565 379.952 12.0243C378.029 11.5024 376.448 11.0329 374.305 10.2388C374.9 9.24988 377.719 6.02591 380.976 4.81224L380.973 4.81473Z" fill="#363E44"/>
      <path d="M202.221 56.8853H55.7515V402.717H202.221V56.8853Z" fill="#4361EE"/>
      <path d="M193.606 385.553H63.8875C63.5829 385.553 63.3381 385.306 63.3381 385.004V361.07C63.3381 360.765 63.5854 360.521 63.8875 360.521H193.606C193.91 360.521 194.155 360.768 194.155 361.07V385.004C194.155 385.308 193.908 385.553 193.606 385.553ZM64.4394 384.452H193.054V361.619H64.4394V384.452Z" fill="#BC8271"/>
      <path d="M130.063 376.788C127.96 376.788 126.252 375.077 126.252 372.977C126.252 370.877 127.963 369.166 130.063 369.166C132.163 369.166 133.874 370.877 133.874 372.977C133.874 375.077 132.163 376.788 130.063 376.788ZM130.063 370.265C128.569 370.265 127.353 371.481 127.353 372.974C127.353 374.468 128.569 375.684 130.063 375.684C131.556 375.684 132.772 374.468 132.772 372.974C132.772 371.481 131.556 370.265 130.063 370.265Z" fill="#D3BBA3"/>
      <path d="M103.145 370.073H70.835V376.056H103.145V370.073Z" fill="#F2E6DC"/>
      <path d="M186.971 370.073H154.661V376.056H186.971V370.073Z" fill="#F2E6DC"/>
      <path d="M193.246 354.322H63.5306C63.2259 354.322 62.9812 354.075 62.9812 353.773V329.839C62.9812 329.534 63.2284 329.29 63.5306 329.29H193.246C193.551 329.29 193.796 329.537 193.796 329.839V353.773C193.796 354.078 193.548 354.322 193.246 354.322ZM64.08 353.221H192.697V330.389H64.08V353.221Z" fill="#BC8271"/>
      <path d="M129.703 345.554C127.6 345.554 125.892 343.844 125.892 341.744C125.892 339.643 127.603 337.933 129.703 337.933C131.803 337.933 133.514 339.643 133.514 341.744C133.514 343.844 131.803 345.554 129.703 345.554ZM129.703 339.032C128.21 339.032 126.994 340.248 126.994 341.741C126.994 343.234 128.21 344.451 129.703 344.451C131.197 344.451 132.413 343.234 132.413 341.741C132.413 340.248 131.197 339.032 129.703 339.032Z" fill="#D3BBA3"/>
      <path d="M102.787 338.839H70.4778V344.823H102.787V338.839Z" fill="#F2E6DC"/>
      <path d="M186.611 338.839H154.301V344.823H186.611V338.839Z" fill="#F2E6DC"/>
      <path d="M193.246 323.089H63.5306C63.2259 323.089 62.9812 322.842 62.9812 322.54V298.606C62.9812 298.301 63.2284 298.056 63.5306 298.056H193.246C193.551 298.056 193.796 298.304 193.796 298.606V322.54C193.796 322.844 193.548 323.089 193.246 323.089ZM64.08 321.988H192.697V299.155H64.08V321.988Z" fill="white"/>
      <path d="M129.703 314.321C127.6 314.321 125.892 312.61 125.892 310.51C125.892 308.41 127.603 306.699 129.703 306.699C131.803 306.699 133.514 308.41 133.514 310.51C133.514 312.61 131.803 314.321 129.703 314.321ZM129.703 307.801C128.21 307.801 126.994 309.017 126.994 310.51C126.994 312.004 128.21 313.22 129.703 313.22C131.197 313.22 132.413 312.004 132.413 310.51C132.413 309.017 131.197 307.801 129.703 307.801Z" fill="#D3BBA3"/>
      <path d="M102.787 307.606H70.4778V313.589H102.787V307.606Z" fill="#F2E6DC"/>
      <path d="M186.611 307.606H154.301V313.589H186.611V307.606Z" fill="#F2E6DC"/>
      <path d="M193.965 292.215H64.2498C63.9452 292.215 63.7004 291.968 63.7004 291.666V267.732C63.7004 267.427 63.9477 267.183 64.2498 267.183H193.965C194.27 267.183 194.515 267.43 194.515 267.732V291.666C194.515 291.971 194.268 292.215 193.965 292.215ZM64.7992 291.114H193.416V268.281H64.8017V291.114H64.7992Z" fill="white"/>
      <path d="M187.336 280.56C187.357 278.759 185.914 277.282 184.112 277.261C182.311 277.24 180.834 278.684 180.814 280.485C180.793 282.286 182.236 283.763 184.037 283.784C185.838 283.804 187.315 282.361 187.336 280.56Z" fill="#D3BBA3"/>
      <path d="M175.57 282.421C176.843 281.148 176.843 279.083 175.57 277.809C174.296 276.535 172.231 276.535 170.957 277.809C169.684 279.083 169.684 281.148 170.957 282.421C172.231 283.695 174.296 283.695 175.57 282.421Z" fill="#D3BBA3"/>
      <path d="M163.735 283.145C165.397 282.451 166.181 280.541 165.486 278.879C164.792 277.217 162.882 276.432 161.22 277.127C159.558 277.822 158.773 279.732 159.468 281.394C160.163 283.056 162.073 283.84 163.735 283.145Z" fill="#BC8271"/>
      <path d="M75.0804 289.284C74.7757 289.284 74.531 289.036 74.531 288.734V270.784C74.531 270.479 74.7782 270.234 75.0804 270.234C75.3826 270.234 75.6298 270.482 75.6298 270.784V288.734C75.6298 289.039 75.3826 289.284 75.0804 289.284Z" fill="#7209B7"/>
      <path d="M70.0533 289.284C69.7486 289.284 69.5039 289.036 69.5039 288.734V278.618C69.5039 278.313 69.7511 278.068 70.0533 278.068C70.3555 278.068 70.6027 278.315 70.6027 278.618V288.734C70.6027 289.039 70.3555 289.284 70.0533 289.284Z" fill="#7209B7"/>
      <path d="M85.3092 277.996C85.0045 277.996 84.7598 277.749 84.7598 277.446V270.964C84.7598 270.659 85.007 270.414 85.3092 270.414C85.6113 270.414 85.8586 270.661 85.8586 270.964V277.446C85.8586 277.751 85.6113 277.996 85.3092 277.996Z" fill="#7209B7"/>
      <path d="M80.2847 289.463C79.9801 289.463 79.7354 289.216 79.7354 288.914V270.964C79.7354 270.659 79.9826 270.414 80.2847 270.414C80.5869 270.414 80.8341 270.661 80.8341 270.964V288.914C80.8341 289.219 80.5869 289.463 80.2847 289.463Z" fill="#7209B7"/>
      <path d="M95.3631 283.375C95.0584 283.375 94.8137 283.128 94.8137 282.826V270.424C94.8137 270.119 95.0609 269.875 95.3631 269.875C95.6653 269.875 95.9125 270.122 95.9125 270.424V282.826C95.9125 283.13 95.6653 283.375 95.3631 283.375Z" fill="#7209B7"/>
      <path d="M90.3387 288.924C90.034 288.924 89.7893 288.677 89.7893 288.374V270.424C89.7893 270.119 90.0365 269.875 90.3387 269.875C90.6409 269.875 90.8881 270.122 90.8881 270.424V288.374C90.8881 288.679 90.6409 288.924 90.3387 288.924Z" fill="#7209B7"/>
      <path d="M105.592 277.998C105.287 277.998 105.043 277.751 105.043 277.449V270.606C105.043 270.302 105.29 270.057 105.592 270.057C105.894 270.057 106.142 270.304 106.142 270.606V277.449C106.142 277.754 105.894 277.998 105.592 277.998Z" fill="#7209B7"/>
      <path d="M100.567 289.104C100.263 289.104 100.018 288.856 100.018 288.554V270.604C100.018 270.299 100.265 270.055 100.567 270.055C100.87 270.055 101.117 270.302 101.117 270.604V288.554C101.117 288.859 100.87 289.104 100.567 289.104Z" fill="#7209B7"/>
      <path d="M194.322 263.319H64.6043C64.2997 263.319 64.0549 263.072 64.0549 262.77V238.836C64.0549 238.532 64.3022 238.287 64.6043 238.287H194.322C194.627 238.287 194.872 238.534 194.872 238.836V262.77C194.872 263.075 194.625 263.319 194.322 263.319ZM65.1562 262.218H193.771V239.386H65.1562V262.218Z" fill="white"/>
      <path d="M187.643 252.192C187.952 250.417 186.764 248.728 184.99 248.418C183.215 248.109 181.526 249.297 181.217 251.071C180.907 252.846 182.095 254.535 183.869 254.844C185.644 255.154 187.333 253.966 187.643 252.192Z" fill="#BC8271"/>
      <path d="M173.618 254.482C175.419 254.482 176.879 253.021 176.879 251.22C176.879 249.419 175.419 247.959 173.618 247.959C171.816 247.959 170.356 249.419 170.356 251.22C170.356 253.021 171.816 254.482 173.618 254.482Z" fill="#D3BBA3"/>
      <path d="M162.849 254.482C164.651 254.482 166.111 253.021 166.111 251.22C166.111 249.419 164.651 247.959 162.849 247.959C161.048 247.959 159.588 249.419 159.588 251.22C159.588 253.021 161.048 254.482 162.849 254.482Z" fill="#D3BBA3"/>
      <path d="M75.4349 260.388C75.1302 260.388 74.8855 260.14 74.8855 259.838V241.888C74.8855 241.583 75.1327 241.338 75.4349 241.338C75.7371 241.338 75.9843 241.586 75.9843 241.888V259.838C75.9843 260.143 75.7371 260.388 75.4349 260.388Z" fill="#7209B7"/>
      <path d="M70.4105 260.388C70.1058 260.388 69.8611 260.14 69.8611 259.838V246.58C69.8611 246.276 70.1083 246.031 70.4105 246.031C70.7127 246.031 70.9599 246.278 70.9599 246.58V259.838C70.9599 260.143 70.7127 260.388 70.4105 260.388Z" fill="#7209B7"/>
      <path d="M85.6663 260.567C85.3617 260.567 85.1169 260.32 85.1169 260.018V242.068C85.1169 241.763 85.3642 241.518 85.6663 241.518C85.9685 241.518 86.2157 241.765 86.2157 242.068V260.018C86.2157 260.323 85.9685 260.567 85.6663 260.567Z" fill="#7209B7"/>
      <path d="M80.6417 260.567C80.337 260.567 80.0923 260.32 80.0923 260.018V252.893C80.0923 252.589 80.3395 252.344 80.6417 252.344C80.9439 252.344 81.1911 252.591 81.1911 252.893V260.018C81.1911 260.323 80.9439 260.567 80.6417 260.567Z" fill="#7209B7"/>
      <path d="M95.7179 260.028C95.4132 260.028 95.1685 259.781 95.1685 259.479V241.528C95.1685 241.224 95.4157 240.979 95.7179 240.979C96.02 240.979 96.2673 241.226 96.2673 241.528V259.479C96.2673 259.783 96.02 260.028 95.7179 260.028Z" fill="#7209B7"/>
      <path d="M90.6932 260.028C90.3885 260.028 90.1438 259.781 90.1438 259.479V254.062C90.1438 253.757 90.391 253.513 90.6932 253.513C90.9954 253.513 91.2426 253.76 91.2426 254.062V259.479C91.2426 259.783 90.9954 260.028 90.6932 260.028Z" fill="#7209B7"/>
      <path d="M105.949 250.404C105.644 250.404 105.4 250.156 105.4 249.854V241.711C105.4 241.406 105.647 241.161 105.949 241.161C106.251 241.161 106.498 241.408 106.498 241.711V249.854C106.498 250.159 106.251 250.404 105.949 250.404Z" fill="#7209B7"/>
      <path d="M100.925 260.208C100.62 260.208 100.375 259.961 100.375 259.658V245.644C100.375 245.339 100.622 245.094 100.925 245.094C101.227 245.094 101.474 245.342 101.474 245.644V259.658C101.474 259.963 101.227 260.208 100.925 260.208Z" fill="#7209B7"/>
      <path d="M193.963 232.266H64.2471C63.9425 232.266 63.6978 232.019 63.6978 231.716V207.783C63.6978 207.478 63.945 207.233 64.2471 207.233H193.963C194.267 207.233 194.512 207.48 194.512 207.783V231.716C194.512 232.021 194.265 232.266 193.963 232.266ZM64.7966 231.165H193.413V208.332H64.7966V231.165Z" fill="white"/>
      <path d="M184.059 223.818C185.86 223.818 187.32 222.357 187.32 220.556C187.32 218.755 185.86 217.295 184.059 217.295C182.257 217.295 180.797 218.755 180.797 220.556C180.797 222.357 182.257 223.818 184.059 223.818Z" fill="#F2E6DC"/>
      <path d="M173.26 223.428C175.062 223.428 176.522 221.968 176.522 220.167C176.522 218.365 175.062 216.905 173.26 216.905C171.459 216.905 169.999 218.365 169.999 220.167C169.999 221.968 171.459 223.428 173.26 223.428Z" fill="#F2E6DC"/>
      <path d="M162.49 223.428C164.291 223.428 165.751 221.968 165.751 220.167C165.751 218.365 164.291 216.905 162.49 216.905C160.688 216.905 159.228 218.365 159.228 220.167C159.228 221.968 160.688 223.428 162.49 223.428Z" fill="#F2E6DC"/>
      <path d="M75.0755 215.794C74.7709 215.794 74.5261 215.547 74.5261 215.245V210.837C74.5261 210.532 74.7734 210.287 75.0755 210.287C75.3777 210.287 75.6249 210.535 75.6249 210.837V215.245C75.6249 215.549 75.3777 215.794 75.0755 215.794Z" fill="#7209B7"/>
      <path d="M70.0509 229.334C69.7462 229.334 69.5015 229.087 69.5015 228.785V210.834C69.5015 210.53 69.7487 210.285 70.0509 210.285C70.353 210.285 70.6003 210.532 70.6003 210.834V228.785C70.6003 229.089 70.353 229.334 70.0509 229.334Z" fill="#7209B7"/>
      <path d="M85.3067 221.638C85.0021 221.638 84.7573 221.39 84.7573 221.088V211.014C84.7573 210.709 85.0046 210.465 85.3067 210.465C85.6089 210.465 85.8561 210.712 85.8561 211.014V221.088C85.8561 221.393 85.6089 221.638 85.3067 221.638Z" fill="#7209B7"/>
      <path d="M80.2821 229.514C79.9774 229.514 79.7327 229.267 79.7327 228.964V211.014C79.7327 210.709 79.9799 210.465 80.2821 210.465C80.5842 210.465 80.8315 210.712 80.8315 211.014V228.964C80.8315 229.269 80.5842 229.514 80.2821 229.514Z" fill="#7209B7"/>
      <path d="M95.3582 228.974C95.0536 228.974 94.8088 228.727 94.8088 228.425V210.475C94.8088 210.17 95.0561 209.925 95.3582 209.925C95.6604 209.925 95.9076 210.173 95.9076 210.475V228.425C95.9076 228.73 95.6604 228.974 95.3582 228.974Z" fill="#7209B7"/>
      <path d="M90.3336 228.974C90.0289 228.974 89.7842 228.727 89.7842 228.425V210.475C89.7842 210.17 90.0314 209.925 90.3336 209.925C90.6358 209.925 90.883 210.173 90.883 210.475V228.425C90.883 228.73 90.6358 228.974 90.3336 228.974Z" fill="#7209B7"/>
      <path d="M105.589 224.212C105.285 224.212 105.04 223.965 105.04 223.663V210.657C105.04 210.352 105.287 210.108 105.589 210.108C105.892 210.108 106.139 210.355 106.139 210.657V223.663C106.139 223.967 105.892 224.212 105.589 224.212Z" fill="#7209B7"/>
      <path d="M100.565 229.154C100.26 229.154 100.016 228.907 100.016 228.605V215.242C100.016 214.937 100.263 214.693 100.565 214.693C100.867 214.693 101.114 214.94 101.114 215.242V228.605C101.114 228.91 100.867 229.154 100.565 229.154Z" fill="#7209B7"/>
      <path d="M193.598 129.593H63.8802C63.5755 129.593 63.3308 129.346 63.3308 129.044V105.11C63.3308 104.805 63.578 104.561 63.8802 104.561H193.598C193.903 104.561 194.148 104.808 194.148 105.11V129.044C194.148 129.348 193.9 129.593 193.598 129.593ZM64.4321 128.492H193.046V105.659H64.4321V128.492Z" fill="white"/>
      <path d="M186.927 118.423C187.216 116.645 186.01 114.969 184.232 114.679C182.455 114.389 180.779 115.595 180.489 117.373C180.199 119.151 181.405 120.827 183.183 121.117C184.961 121.407 186.637 120.2 186.927 118.423Z" fill="#F2E6DC"/>
      <path d="M172.893 120.758C174.695 120.758 176.155 119.298 176.155 117.496C176.155 115.695 174.695 114.235 172.893 114.235C171.092 114.235 169.632 115.695 169.632 117.496C169.632 119.298 171.092 120.758 172.893 120.758Z" fill="#F2E6DC"/>
      <path d="M162.125 120.758C163.926 120.758 165.387 119.298 165.387 117.496C165.387 115.695 163.926 114.235 162.125 114.235C160.324 114.235 158.864 115.695 158.864 117.496C158.864 119.298 160.324 120.758 162.125 120.758Z" fill="#F2E6DC"/>
      <path d="M74.7108 126.664C74.4061 126.664 74.1614 126.417 74.1614 126.115V108.164C74.1614 107.859 74.4086 107.615 74.7108 107.615C75.0129 107.615 75.2602 107.862 75.2602 108.164V126.115C75.2602 126.419 75.0129 126.664 74.7108 126.664Z" fill="#7209B7"/>
      <path d="M69.6864 126.664C69.3817 126.664 69.137 126.417 69.137 126.115V112.857C69.137 112.552 69.3842 112.307 69.6864 112.307C69.9885 112.307 70.2358 112.554 70.2358 112.857V126.115C70.2358 126.419 69.9885 126.664 69.6864 126.664Z" fill="#7209B7"/>
      <path d="M84.9422 126.841C84.6376 126.841 84.3928 126.594 84.3928 126.292V108.341C84.3928 108.037 84.6401 107.792 84.9422 107.792C85.2444 107.792 85.4916 108.039 85.4916 108.341V126.292C85.4916 126.596 85.2444 126.841 84.9422 126.841Z" fill="#7209B7"/>
      <path d="M79.9176 126.841C79.6129 126.841 79.3682 126.594 79.3682 126.292V119.167C79.3682 118.862 79.6154 118.618 79.9176 118.618C80.2197 118.618 80.467 118.865 80.467 119.167V126.292C80.467 126.596 80.2197 126.841 79.9176 126.841Z" fill="#7209B7"/>
      <path d="M94.9937 126.304C94.6891 126.304 94.4443 126.057 94.4443 125.755V107.805C94.4443 107.5 94.6916 107.255 94.9937 107.255C95.2959 107.255 95.5431 107.502 95.5431 107.805V125.755C95.5431 126.06 95.2959 126.304 94.9937 126.304Z" fill="#7209B7"/>
      <path d="M89.9691 126.304C89.6644 126.304 89.4197 126.057 89.4197 125.755V120.338C89.4197 120.034 89.6669 119.789 89.9691 119.789C90.2712 119.789 90.5185 120.036 90.5185 120.338V125.755C90.5185 126.06 90.2712 126.304 89.9691 126.304Z" fill="#7209B7"/>
      <path d="M105.225 116.677C104.92 116.677 104.676 116.43 104.676 116.128V107.984C104.676 107.68 104.923 107.435 105.225 107.435C105.527 107.435 105.774 107.682 105.774 107.984V116.128C105.774 116.433 105.527 116.677 105.225 116.677Z" fill="#7209B7"/>
      <path d="M100.2 126.484C99.8956 126.484 99.6509 126.237 99.6509 125.935V111.92C99.6509 111.615 99.8981 111.371 100.2 111.371C100.502 111.371 100.75 111.618 100.75 111.92V125.935C100.75 126.239 100.502 126.484 100.2 126.484Z" fill="#7209B7"/>
      <path d="M193.239 98.5422H63.523C63.2184 98.5422 62.9736 98.295 62.9736 97.9928V74.059C62.9736 73.7543 63.2209 73.5096 63.523 73.5096H193.239C193.543 73.5096 193.788 73.7568 193.788 74.059V97.9928C193.788 98.2974 193.541 98.5422 193.239 98.5422ZM64.0724 97.4409H192.689V74.6084H64.0724V97.4409Z" fill="white"/>
      <path d="M186.583 86.9584C186.656 85.1586 185.257 83.6403 183.457 83.567C181.657 83.4938 180.139 84.8934 180.065 86.6932C179.992 88.4929 181.392 90.0113 183.192 90.0845C184.991 90.1577 186.51 88.7581 186.583 86.9584Z" fill="#F2E6DC"/>
      <path d="M172.536 89.7043C174.337 89.7043 175.798 88.2442 175.798 86.4429C175.798 84.6417 174.337 83.1815 172.536 83.1815C170.735 83.1815 169.275 84.6417 169.275 86.4429C169.275 88.2442 170.735 89.7043 172.536 89.7043Z" fill="#F2E6DC"/>
      <path d="M161.766 89.7043C163.567 89.7043 165.027 88.2442 165.027 86.4429C165.027 84.6417 163.567 83.1815 161.766 83.1815C159.964 83.1815 158.504 84.6417 158.504 86.4429C158.504 88.2442 159.964 89.7043 161.766 89.7043Z" fill="#F2E6DC"/>
      <path d="M74.3512 82.0677C74.0465 82.0677 73.8018 81.8205 73.8018 81.5183V77.1106C73.8018 76.806 74.049 76.5612 74.3512 76.5612C74.6533 76.5612 74.9006 76.8085 74.9006 77.1106V81.5183C74.9006 81.823 74.6533 82.0677 74.3512 82.0677Z" fill="#7209B7"/>
      <path d="M69.3267 95.6104C69.0221 95.6104 68.7773 95.3632 68.7773 95.061V77.1106C68.7773 76.806 69.0246 76.5612 69.3267 76.5612C69.6289 76.5612 69.8761 76.8085 69.8761 77.1106V95.061C69.8761 95.3657 69.6289 95.6104 69.3267 95.6104Z" fill="#7209B7"/>
      <path d="M84.5824 87.9138C84.2777 87.9138 84.033 87.6666 84.033 87.3644V77.2904C84.033 76.9858 84.2802 76.741 84.5824 76.741C84.8845 76.741 85.1318 76.9883 85.1318 77.2904V87.3644C85.1318 87.6691 84.8845 87.9138 84.5824 87.9138Z" fill="#7209B7"/>
      <path d="M79.5579 95.7902C79.2533 95.7902 79.0085 95.543 79.0085 95.2408V77.2904C79.0085 76.9858 79.2558 76.741 79.5579 76.741C79.8601 76.741 80.1073 76.9883 80.1073 77.2904V95.2408C80.1073 95.5455 79.8601 95.7902 79.5579 95.7902Z" fill="#7209B7"/>
      <path d="M94.6339 95.2508C94.3292 95.2508 94.0845 95.0036 94.0845 94.7014V76.751C94.0845 76.4464 94.3317 76.2016 94.6339 76.2016C94.936 76.2016 95.1833 76.4489 95.1833 76.751V94.7014C95.1833 95.0061 94.936 95.2508 94.6339 95.2508Z" fill="#7209B7"/>
      <path d="M89.6095 95.2508C89.3048 95.2508 89.0601 95.0036 89.0601 94.7014V76.751C89.0601 76.4464 89.3073 76.2016 89.6095 76.2016C89.9116 76.2016 90.1589 76.4489 90.1589 76.751V94.7014C90.1589 95.0061 89.9116 95.2508 89.6095 95.2508Z" fill="#7209B7"/>
      <path d="M104.865 90.486C104.561 90.486 104.316 90.2388 104.316 89.9366V76.9308C104.316 76.6262 104.563 76.3814 104.865 76.3814C105.167 76.3814 105.415 76.6287 105.415 76.9308V89.9366C105.415 90.2413 105.167 90.486 104.865 90.486Z" fill="#7209B7"/>
      <path d="M99.8407 95.4306C99.536 95.4306 99.2913 95.1834 99.2913 94.8812V81.5183C99.2913 81.2136 99.5385 80.9689 99.8407 80.9689C100.143 80.9689 100.39 81.2161 100.39 81.5183V94.8812C100.39 95.1859 100.143 95.4306 99.8407 95.4306Z" fill="#7209B7"/>
      <path d="M193.628 182.558H64.6294V194.045H193.628V182.558Z" fill="#F2E6DC"/>
      <path d="M70.8131 190.683C71.9982 190.572 72.8695 189.522 72.7591 188.337C72.6488 187.152 71.5986 186.28 70.4134 186.391C69.2283 186.501 68.357 187.551 68.4674 188.736C68.5778 189.922 69.628 190.793 70.8131 190.683Z" fill="#4361EE"/>
      <path d="M190.125 189.822C190.967 188.98 190.967 187.615 190.125 186.774C189.284 185.932 187.919 185.932 187.077 186.774C186.236 187.615 186.236 188.98 187.077 189.822C187.919 190.663 189.284 190.663 190.125 189.822Z" fill="#4361EE"/>
      <path d="M193.149 162.452H64.1499V173.94H193.149V162.452Z" fill="#F2E6DC"/>
      <path d="M70.9638 170.416C72.0644 169.963 72.5892 168.703 72.136 167.602C71.6828 166.502 70.4233 165.977 69.3226 166.43C68.222 166.883 67.6972 168.143 68.1504 169.244C68.6036 170.344 69.8632 170.869 70.9638 170.416Z" fill="#4361EE"/>
      <path d="M188.122 170.349C189.312 170.349 190.277 169.384 190.277 168.193C190.277 167.003 189.312 166.038 188.122 166.038C186.931 166.038 185.967 167.003 185.967 168.193C185.967 169.384 186.931 170.349 188.122 170.349Z" fill="#4361EE"/>
      <path d="M192.909 142.829H63.9102V154.316H192.909V142.829Z" fill="#F2E6DC"/>
      <path d="M71.4182 150.334C72.2598 149.493 72.2598 148.128 71.4182 147.286C70.5765 146.445 69.212 146.445 68.3704 147.286C67.5287 148.128 67.5287 149.493 68.3704 150.334C69.212 151.176 70.5765 151.176 71.4182 150.334Z" fill="#4361EE"/>
      <path d="M187.882 150.725C189.072 150.725 190.037 149.76 190.037 148.57C190.037 147.38 189.072 146.415 187.882 146.415C186.692 146.415 185.727 147.38 185.727 148.57C185.727 149.76 186.692 150.725 187.882 150.725Z" fill="#4361EE"/>
      <path d="M200.089 401.136H49.3111C49.0065 401.136 48.7617 400.889 48.7617 400.587V52.3627C48.7617 52.058 49.009 51.8133 49.3111 51.8133H200.089C200.393 51.8133 200.638 52.0605 200.638 52.3627V400.587C200.638 400.891 200.391 401.136 200.089 401.136ZM49.863 400.035H199.537V52.9146H49.8605V400.035H49.863Z" fill="#363E44"/>
      <path d="M300.774 46.8712C300.469 46.8712 300.224 46.624 300.224 46.3218V32.447L126.686 30.9636L126.207 45.8423C126.197 46.147 125.94 46.3867 125.64 46.3743C125.335 46.3643 125.098 46.1096 125.108 45.8074L125.605 30.3918C125.615 30.0921 125.86 29.8698 126.16 29.8598L300.781 31.3507C301.083 31.3532 301.328 31.5979 301.328 31.9001V46.3218C301.328 46.6265 301.081 46.8712 300.779 46.8712H300.774Z" fill="#363E44"/>
      <path d="M374.613 56.8853H228.143V402.717H374.613V56.8853Z" fill="#4361EE"/>
      <path d="M365.997 385.553H236.282C235.977 385.553 235.732 385.306 235.732 385.004V361.07C235.732 360.765 235.979 360.521 236.282 360.521H365.997C366.302 360.521 366.547 360.768 366.547 361.07V385.004C366.547 385.308 366.299 385.553 365.997 385.553ZM236.831 384.452H365.445V361.619H236.831V384.452Z" fill="#BC8271"/>
      <path d="M302.454 376.788C300.351 376.788 298.643 375.077 298.643 372.977C298.643 370.877 300.354 369.166 302.454 369.166C304.554 369.166 306.265 370.877 306.265 372.977C306.265 375.077 304.554 376.788 302.454 376.788ZM302.454 370.265C300.961 370.265 299.745 371.481 299.745 372.974C299.745 374.468 300.961 375.684 302.454 375.684C303.948 375.684 305.164 374.468 305.164 372.974C305.164 371.481 303.948 370.265 302.454 370.265Z" fill="#D3BBA3"/>
      <path d="M275.538 370.073H243.229V376.056H275.538V370.073Z" fill="#F2E6DC"/>
      <path d="M359.362 370.073H327.052V376.056H359.362V370.073Z" fill="#F2E6DC"/>
      <path d="M365.638 354.322H235.922C235.617 354.322 235.373 354.075 235.373 353.773V329.839C235.373 329.534 235.62 329.29 235.922 329.29H365.638C365.942 329.29 366.187 329.537 366.187 329.839V353.773C366.187 354.078 365.94 354.322 365.638 354.322ZM236.471 353.221H365.086V330.389H236.474V353.221H236.471Z" fill="#BC8271"/>
      <path d="M302.097 345.554C299.994 345.554 298.286 343.844 298.286 341.744C298.286 339.643 299.997 337.933 302.097 337.933C304.197 337.933 305.908 339.643 305.908 341.744C305.908 343.844 304.197 345.554 302.097 345.554ZM302.097 339.032C300.604 339.032 299.387 340.248 299.387 341.741C299.387 343.234 300.604 344.451 302.097 344.451C303.59 344.451 304.807 343.234 304.807 341.741C304.807 340.248 303.59 339.032 302.097 339.032Z" fill="#D3BBA3"/>
      <path d="M275.179 338.839H242.869V344.823H275.179V338.839Z" fill="#F2E6DC"/>
      <path d="M359.005 338.839H326.695V344.823H359.005V338.839Z" fill="#F2E6DC"/>
      <path d="M365.638 323.089H235.922C235.617 323.089 235.373 322.842 235.373 322.54V298.606C235.373 298.301 235.62 298.056 235.922 298.056H365.638C365.942 298.056 366.187 298.304 366.187 298.606V322.54C366.187 322.844 365.94 323.089 365.638 323.089ZM236.471 321.988H365.086V299.155H236.474V321.988H236.471Z" fill="#BC8271"/>
      <path d="M302.097 314.321C299.994 314.321 298.286 312.61 298.286 310.51C298.286 308.41 299.997 306.699 302.097 306.699C304.197 306.699 305.908 308.41 305.908 310.51C305.908 312.61 304.197 314.321 302.097 314.321ZM302.097 307.801C300.604 307.801 299.387 309.017 299.387 310.51C299.387 312.004 300.604 313.22 302.097 313.22C303.59 313.22 304.807 312.004 304.807 310.51C304.807 309.017 303.59 307.801 302.097 307.801Z" fill="#D3BBA3"/>
      <path d="M275.179 307.606H242.869V313.589H275.179V307.606Z" fill="#F2E6DC"/>
      <path d="M359.005 307.606H326.695V313.589H359.005V307.606Z" fill="#F2E6DC"/>
      <path d="M366.359 292.215H236.644C236.339 292.215 236.094 291.968 236.094 291.666V267.732C236.094 267.427 236.341 267.183 236.644 267.183H366.359C366.664 267.183 366.909 267.43 366.909 267.732V291.666C366.909 291.971 366.661 292.215 366.359 292.215ZM237.193 291.114H365.807V268.281H237.193V291.114Z" fill="white"/>
      <path d="M356.455 283.767C358.256 283.767 359.716 282.307 359.716 280.506C359.716 278.704 358.256 277.244 356.455 277.244C354.654 277.244 353.194 278.704 353.194 280.506C353.194 282.307 354.654 283.767 356.455 283.767Z" fill="#D3BBA3"/>
      <path d="M346.903 283.159C348.565 282.464 349.349 280.554 348.655 278.892C347.96 277.23 346.05 276.446 344.388 277.14C342.726 277.835 341.942 279.745 342.636 281.407C343.331 283.069 345.241 283.853 346.903 283.159Z" fill="#D3BBA3"/>
      <path d="M336.133 283.155C337.795 282.461 338.58 280.551 337.885 278.889C337.191 277.227 335.28 276.442 333.618 277.137C331.956 277.831 331.172 279.742 331.867 281.404C332.561 283.066 334.472 283.85 336.133 283.155Z" fill="#BC8271"/>
      <path d="M247.472 289.284C247.167 289.284 246.922 289.036 246.922 288.734V270.784C246.922 270.479 247.17 270.234 247.472 270.234C247.774 270.234 248.021 270.482 248.021 270.784V288.734C248.021 289.039 247.774 289.284 247.472 289.284Z" fill="#7209B7"/>
      <path d="M242.447 289.284C242.143 289.284 241.898 289.036 241.898 288.734V278.618C241.898 278.313 242.145 278.068 242.447 278.068C242.75 278.068 242.997 278.315 242.997 278.618V288.734C242.997 289.039 242.75 289.284 242.447 289.284Z" fill="#7209B7"/>
      <path d="M257.703 277.996C257.399 277.996 257.154 277.749 257.154 277.446V270.964C257.154 270.659 257.401 270.414 257.703 270.414C258.005 270.414 258.253 270.661 258.253 270.964V277.446C258.253 277.751 258.005 277.996 257.703 277.996Z" fill="#7209B7"/>
      <path d="M252.679 289.463C252.374 289.463 252.129 289.216 252.129 288.914V270.964C252.129 270.659 252.376 270.414 252.679 270.414C252.981 270.414 253.228 270.661 253.228 270.964V288.914C253.228 289.219 252.981 289.463 252.679 289.463Z" fill="#7209B7"/>
      <path d="M267.755 283.375C267.45 283.375 267.205 283.128 267.205 282.826V270.424C267.205 270.119 267.453 269.875 267.755 269.875C268.057 269.875 268.304 270.122 268.304 270.424V282.826C268.304 283.13 268.057 283.375 267.755 283.375Z" fill="#7209B7"/>
      <path d="M262.73 288.924C262.425 288.924 262.181 288.677 262.181 288.374V270.424C262.181 270.119 262.428 269.875 262.73 269.875C263.032 269.875 263.279 270.122 263.279 270.424V288.374C263.279 288.679 263.032 288.924 262.73 288.924Z" fill="#7209B7"/>
      <path d="M277.986 277.998C277.681 277.998 277.437 277.751 277.437 277.449V270.606C277.437 270.302 277.684 270.057 277.986 270.057C278.288 270.057 278.535 270.304 278.535 270.606V277.449C278.535 277.754 278.288 277.998 277.986 277.998Z" fill="#7209B7"/>
      <path d="M272.962 289.104C272.657 289.104 272.412 288.856 272.412 288.554V270.604C272.412 270.299 272.659 270.055 272.962 270.055C273.264 270.055 273.511 270.302 273.511 270.604V288.554C273.511 288.859 273.264 289.104 272.962 289.104Z" fill="#7209B7"/>
      <path d="M366.714 263.319H236.998C236.693 263.319 236.449 263.072 236.449 262.77V238.836C236.449 238.532 236.696 238.287 236.998 238.287H366.714C367.018 238.287 367.263 238.534 367.263 238.836V262.77C367.263 263.075 367.016 263.319 366.714 263.319ZM237.548 262.218H366.162V239.386H237.548V262.218Z" fill="white"/>
      <path d="M359.214 253.784C360.428 252.454 360.334 250.391 359.004 249.177C357.673 247.962 355.61 248.057 354.396 249.387C353.182 250.717 353.276 252.78 354.606 253.994C355.936 255.209 357.999 255.115 359.214 253.784Z" fill="#F2E6DC"/>
      <path d="M346.011 254.482C347.813 254.482 349.273 253.021 349.273 251.22C349.273 249.419 347.813 247.959 346.011 247.959C344.21 247.959 342.75 249.419 342.75 251.22C342.75 253.021 344.21 254.482 346.011 254.482Z" fill="#F2E6DC"/>
      <path d="M335.241 254.482C337.042 254.482 338.502 253.021 338.502 251.22C338.502 249.419 337.042 247.959 335.241 247.959C333.439 247.959 331.979 249.419 331.979 251.22C331.979 253.021 333.439 254.482 335.241 254.482Z" fill="#F2E6DC"/>
      <path d="M247.826 260.388C247.522 260.388 247.277 260.14 247.277 259.838V241.888C247.277 241.583 247.524 241.338 247.826 241.338C248.128 241.338 248.376 241.586 248.376 241.888V259.838C248.376 260.143 248.128 260.388 247.826 260.388Z" fill="#7209B7"/>
      <path d="M242.802 260.388C242.497 260.388 242.252 260.14 242.252 259.838V246.58C242.252 246.276 242.5 246.031 242.802 246.031C243.104 246.031 243.351 246.278 243.351 246.58V259.838C243.351 260.143 243.104 260.388 242.802 260.388Z" fill="#7209B7"/>
      <path d="M258.058 260.567C257.753 260.567 257.508 260.32 257.508 260.018V242.068C257.508 241.763 257.756 241.518 258.058 241.518C258.36 241.518 258.607 241.765 258.607 242.068V260.018C258.607 260.323 258.36 260.567 258.058 260.567Z" fill="#7209B7"/>
      <path d="M253.033 260.567C252.729 260.567 252.484 260.32 252.484 260.018V252.893C252.484 252.589 252.731 252.344 253.033 252.344C253.335 252.344 253.583 252.591 253.583 252.893V260.018C253.583 260.323 253.335 260.567 253.033 260.567Z" fill="#7209B7"/>
      <path d="M268.109 260.028C267.805 260.028 267.56 259.781 267.56 259.479V241.528C267.56 241.224 267.807 240.979 268.109 240.979C268.411 240.979 268.659 241.226 268.659 241.528V259.479C268.659 259.783 268.411 260.028 268.109 260.028Z" fill="#7209B7"/>
      <path d="M263.085 260.028C262.78 260.028 262.535 259.781 262.535 259.479V254.062C262.535 253.757 262.782 253.513 263.085 253.513C263.387 253.513 263.634 253.76 263.634 254.062V259.479C263.634 259.783 263.387 260.028 263.085 260.028Z" fill="#7209B7"/>
      <path d="M278.34 250.404C278.036 250.404 277.791 250.156 277.791 249.854V241.711C277.791 241.406 278.038 241.161 278.34 241.161C278.643 241.161 278.89 241.408 278.89 241.711V249.854C278.89 250.159 278.643 250.404 278.34 250.404Z" fill="#7209B7"/>
      <path d="M273.316 260.208C273.011 260.208 272.767 259.961 272.767 259.658V245.644C272.767 245.339 273.014 245.094 273.316 245.094C273.618 245.094 273.865 245.342 273.865 245.644V259.658C273.865 259.963 273.618 260.208 273.316 260.208Z" fill="#7209B7"/>
      <path d="M366.357 232.266H236.639C236.334 232.266 236.089 232.019 236.089 231.716V207.783C236.089 207.478 236.336 207.233 236.639 207.233H366.354C366.659 207.233 366.903 207.48 366.903 207.783V231.716C366.903 232.021 366.656 232.266 366.354 232.266H366.357ZM237.19 231.165H365.805V208.332H237.19V231.165Z" fill="white"/>
      <path d="M356.453 223.818C358.254 223.818 359.714 222.357 359.714 220.556C359.714 218.755 358.254 217.295 356.453 217.295C354.651 217.295 353.191 218.755 353.191 220.556C353.191 222.357 354.651 223.818 356.453 223.818Z" fill="#F2E6DC"/>
      <path d="M345.652 223.428C347.453 223.428 348.913 221.968 348.913 220.167C348.913 218.365 347.453 216.905 345.652 216.905C343.851 216.905 342.39 218.365 342.39 220.167C342.39 221.968 343.851 223.428 345.652 223.428Z" fill="#F2E6DC"/>
      <path d="M334.881 223.428C336.682 223.428 338.142 221.968 338.142 220.167C338.142 218.365 336.682 216.905 334.881 216.905C333.08 216.905 331.62 218.365 331.62 220.167C331.62 221.968 333.08 223.428 334.881 223.428Z" fill="#F2E6DC"/>
      <path d="M247.469 215.794C247.165 215.794 246.92 215.547 246.92 215.245V210.837C246.92 210.532 247.167 210.287 247.469 210.287C247.771 210.287 248.019 210.535 248.019 210.837V215.245C248.019 215.549 247.771 215.794 247.469 215.794Z" fill="#7209B7"/>
      <path d="M242.445 229.334C242.14 229.334 241.895 229.087 241.895 228.785V210.834C241.895 210.53 242.142 210.285 242.445 210.285C242.747 210.285 242.994 210.532 242.994 210.834V228.785C242.994 229.089 242.747 229.334 242.445 229.334Z" fill="#7209B7"/>
      <path d="M257.698 221.638C257.393 221.638 257.149 221.39 257.149 221.088V211.014C257.149 210.709 257.396 210.465 257.698 210.465C258 210.465 258.247 210.712 258.247 211.014V221.088C258.247 221.393 258 221.638 257.698 221.638Z" fill="#7209B7"/>
      <path d="M252.674 229.514C252.369 229.514 252.124 229.267 252.124 228.964V211.014C252.124 210.709 252.372 210.465 252.674 210.465C252.976 210.465 253.223 210.712 253.223 211.014V228.964C253.223 229.269 252.976 229.514 252.674 229.514Z" fill="#7209B7"/>
      <path d="M267.752 228.974C267.447 228.974 267.203 228.727 267.203 228.425V210.475C267.203 210.17 267.45 209.925 267.752 209.925C268.054 209.925 268.301 210.173 268.301 210.475V228.425C268.301 228.73 268.054 228.974 267.752 228.974Z" fill="#7209B7"/>
      <path d="M262.728 228.974C262.423 228.974 262.178 228.727 262.178 228.425V210.475C262.178 210.17 262.425 209.925 262.728 209.925C263.03 209.925 263.277 210.173 263.277 210.475V228.425C263.277 228.73 263.03 228.974 262.728 228.974Z" fill="#7209B7"/>
      <path d="M277.983 224.212C277.679 224.212 277.434 223.965 277.434 223.663V210.657C277.434 210.352 277.681 210.108 277.983 210.108C278.286 210.108 278.533 210.355 278.533 210.657V223.663C278.533 223.967 278.286 224.212 277.983 224.212Z" fill="#7209B7"/>
      <path d="M272.959 229.154C272.654 229.154 272.409 228.907 272.409 228.605V215.242C272.409 214.937 272.657 214.693 272.959 214.693C273.261 214.693 273.508 214.94 273.508 215.242V228.605C273.508 228.91 273.261 229.154 272.959 229.154Z" fill="#7209B7"/>
      <path d="M365.99 129.593H236.274C235.969 129.593 235.725 129.346 235.725 129.044V105.11C235.725 104.805 235.972 104.561 236.274 104.561H365.99C366.294 104.561 366.539 104.808 366.539 105.11V129.044C366.539 129.348 366.292 129.593 365.99 129.593ZM236.823 128.492H365.438V105.659H236.823V128.492Z" fill="white"/>
      <path d="M359.268 118.644C359.684 116.891 358.601 115.133 356.848 114.717C355.096 114.301 353.338 115.384 352.921 117.137C352.505 118.889 353.588 120.647 355.341 121.063C357.093 121.48 358.851 120.396 359.268 118.644Z" fill="#F2E6DC"/>
      <path d="M345.287 120.758C347.089 120.758 348.549 119.298 348.549 117.496C348.549 115.695 347.089 114.235 345.287 114.235C343.486 114.235 342.026 115.695 342.026 117.496C342.026 119.298 343.486 120.758 345.287 120.758Z" fill="#F2E6DC"/>
      <path d="M334.517 120.758C336.318 120.758 337.778 119.298 337.778 117.496C337.778 115.695 336.318 114.235 334.517 114.235C332.715 114.235 331.255 115.695 331.255 117.496C331.255 119.298 332.715 120.758 334.517 120.758Z" fill="#F2E6DC"/>
      <path d="M247.102 126.664C246.797 126.664 246.553 126.417 246.553 126.115V108.164C246.553 107.859 246.8 107.615 247.102 107.615C247.404 107.615 247.652 107.862 247.652 108.164V126.115C247.652 126.419 247.404 126.664 247.102 126.664Z" fill="#7209B7"/>
      <path d="M242.078 126.664C241.773 126.664 241.528 126.417 241.528 126.115V112.857C241.528 112.552 241.776 112.307 242.078 112.307C242.38 112.307 242.627 112.554 242.627 112.857V126.115C242.627 126.419 242.38 126.664 242.078 126.664Z" fill="#7209B7"/>
      <path d="M257.334 126.841C257.029 126.841 256.784 126.594 256.784 126.292V108.341C256.784 108.037 257.031 107.792 257.334 107.792C257.636 107.792 257.883 108.039 257.883 108.341V126.292C257.883 126.596 257.636 126.841 257.334 126.841Z" fill="#7209B7"/>
      <path d="M252.309 126.841C252.004 126.841 251.76 126.594 251.76 126.292V119.167C251.76 118.862 252.007 118.618 252.309 118.618C252.611 118.618 252.858 118.865 252.858 119.167V126.292C252.858 126.596 252.611 126.841 252.309 126.841Z" fill="#7209B7"/>
      <path d="M267.385 126.304C267.08 126.304 266.836 126.057 266.836 125.755V107.805C266.836 107.5 267.083 107.255 267.385 107.255C267.687 107.255 267.934 107.502 267.934 107.805V125.755C267.934 126.06 267.687 126.304 267.385 126.304Z" fill="#7209B7"/>
      <path d="M262.36 126.304C262.056 126.304 261.811 126.057 261.811 125.755V120.338C261.811 120.034 262.058 119.789 262.36 119.789C262.663 119.789 262.91 120.036 262.91 120.338V125.755C262.91 126.06 262.663 126.304 262.36 126.304Z" fill="#7209B7"/>
      <path d="M277.616 116.677C277.312 116.677 277.067 116.43 277.067 116.128V107.984C277.067 107.68 277.314 107.435 277.616 107.435C277.918 107.435 278.166 107.682 278.166 107.984V116.128C278.166 116.433 277.918 116.677 277.616 116.677Z" fill="#7209B7"/>
      <path d="M272.592 126.484C272.287 126.484 272.042 126.237 272.042 125.935V111.92C272.042 111.615 272.29 111.371 272.592 111.371C272.894 111.371 273.141 111.618 273.141 111.92V125.935C273.141 126.239 272.894 126.484 272.592 126.484Z" fill="#7209B7"/>
      <path d="M365.632 98.5422H235.914C235.61 98.5422 235.365 98.295 235.365 97.9928V74.059C235.365 73.7543 235.612 73.5096 235.914 73.5096H365.63C365.935 73.5096 366.179 73.7568 366.179 74.059V97.9928C366.179 98.2974 365.932 98.5422 365.63 98.5422H365.632ZM236.466 97.4409H365.081V74.6084H236.466V97.4409Z" fill="white"/>
      <path d="M357.016 89.7982C358.67 89.084 359.431 87.1645 358.717 85.5109C358.003 83.8573 356.083 83.0958 354.43 83.81C352.776 84.5242 352.015 86.4437 352.729 88.0973C353.443 89.7509 355.362 90.5124 357.016 89.7982Z" fill="#F2E6DC"/>
      <path d="M344.928 89.7043C346.729 89.7043 348.189 88.2442 348.189 86.4429C348.189 84.6417 346.729 83.1815 344.928 83.1815C343.126 83.1815 341.666 84.6417 341.666 86.4429C341.666 88.2442 343.126 89.7043 344.928 89.7043Z" fill="#F2E6DC"/>
      <path d="M334.157 89.7043C335.958 89.7043 337.418 88.2442 337.418 86.4429C337.418 84.6417 335.958 83.1815 334.157 83.1815C332.356 83.1815 330.896 84.6417 330.896 86.4429C330.896 88.2442 332.356 89.7043 334.157 89.7043Z" fill="#F2E6DC"/>
      <path d="M246.745 82.0677C246.441 82.0677 246.196 81.8205 246.196 81.5183V77.1106C246.196 76.806 246.443 76.5612 246.745 76.5612C247.047 76.5612 247.295 76.8085 247.295 77.1106V81.5183C247.295 81.823 247.047 82.0677 246.745 82.0677Z" fill="#7209B7"/>
      <path d="M241.721 95.6104C241.416 95.6104 241.171 95.3632 241.171 95.061V77.1106C241.171 76.806 241.418 76.5612 241.721 76.5612C242.023 76.5612 242.27 76.8085 242.27 77.1106V95.061C242.27 95.3657 242.023 95.6104 241.721 95.6104Z" fill="#7209B7"/>
      <path d="M256.976 87.9138C256.672 87.9138 256.427 87.6666 256.427 87.3644V77.2904C256.427 76.9858 256.674 76.741 256.976 76.741C257.279 76.741 257.526 76.9883 257.526 77.2904V87.3644C257.526 87.6691 257.279 87.9138 256.976 87.9138Z" fill="#7209B7"/>
      <path d="M251.952 95.7902C251.647 95.7902 251.403 95.543 251.403 95.2408V77.2904C251.403 76.9858 251.65 76.741 251.952 76.741C252.254 76.741 252.501 76.9883 252.501 77.2904V95.2408C252.501 95.5455 252.254 95.7902 251.952 95.7902Z" fill="#7209B7"/>
      <path d="M267.028 95.2508C266.723 95.2508 266.479 95.0036 266.479 94.7014V76.751C266.479 76.4464 266.726 76.2016 267.028 76.2016C267.33 76.2016 267.577 76.4489 267.577 76.751V94.7014C267.577 95.0061 267.33 95.2508 267.028 95.2508Z" fill="#7209B7"/>
      <path d="M262.003 95.2508C261.699 95.2508 261.454 95.0036 261.454 94.7014V76.751C261.454 76.4464 261.701 76.2016 262.003 76.2016C262.305 76.2016 262.553 76.4489 262.553 76.751V94.7014C262.553 95.0061 262.305 95.2508 262.003 95.2508Z" fill="#7209B7"/>
      <path d="M277.259 90.486C276.954 90.486 276.71 90.2388 276.71 89.9366V76.9308C276.71 76.6262 276.957 76.3814 277.259 76.3814C277.561 76.3814 277.808 76.6287 277.808 76.9308V89.9366C277.808 90.2413 277.561 90.486 277.259 90.486Z" fill="#7209B7"/>
      <path d="M272.235 95.4306C271.93 95.4306 271.685 95.1834 271.685 94.8812V81.5183C271.685 81.2136 271.933 80.9689 272.235 80.9689C272.537 80.9689 272.784 81.2161 272.784 81.5183V94.8812C272.784 95.1859 272.537 95.4306 272.235 95.4306Z" fill="#7209B7"/>
      <path d="M366.02 182.558H237.021V194.045H366.02V182.558Z" fill="#F2E6DC"/>
      <path d="M245.124 188.867C245.311 187.691 244.509 186.587 243.333 186.401C242.158 186.214 241.053 187.016 240.867 188.192C240.681 189.367 241.483 190.471 242.658 190.658C243.834 190.844 244.938 190.042 245.124 188.867Z" fill="#4361EE"/>
      <path d="M361.816 190.261C362.916 189.808 363.441 188.548 362.988 187.448C362.535 186.347 361.275 185.822 360.175 186.275C359.074 186.729 358.549 187.988 359.002 189.089C359.456 190.189 360.715 190.714 361.816 190.261Z" fill="#4361EE"/>
      <path d="M365.54 162.452H236.541V173.94H365.54V162.452Z" fill="#F2E6DC"/>
      <path d="M243.349 170.404C244.449 169.951 244.974 168.691 244.521 167.591C244.068 166.49 242.808 165.965 241.708 166.418C240.607 166.871 240.082 168.131 240.535 169.232C240.989 170.332 242.248 170.857 243.349 170.404Z" fill="#4361EE"/>
      <path d="M360.513 170.349C361.703 170.349 362.668 169.384 362.668 168.193C362.668 167.003 361.703 166.038 360.513 166.038C359.323 166.038 358.358 167.003 358.358 168.193C358.358 169.384 359.323 170.349 360.513 170.349Z" fill="#4361EE"/>
      <path d="M365.3 142.829H236.302V154.316H365.3V142.829Z" fill="#F2E6DC"/>
      <path d="M243.11 150.781C244.21 150.328 244.735 149.069 244.282 147.968C243.829 146.867 242.569 146.343 241.469 146.796C240.368 147.249 239.843 148.509 240.296 149.609C240.75 150.71 242.009 151.235 243.11 150.781Z" fill="#4361EE"/>
      <path d="M360.276 150.725C361.466 150.725 362.431 149.76 362.431 148.57C362.431 147.38 361.466 146.415 360.276 146.415C359.086 146.415 358.121 147.38 358.121 148.57C358.121 149.76 359.086 150.725 360.276 150.725Z" fill="#4361EE"/>
      <path d="M372.48 401.136H221.702C221.398 401.136 221.153 400.889 221.153 400.587V52.3627C221.153 52.058 221.4 51.8133 221.702 51.8133H372.48C372.785 51.8133 373.029 52.0605 373.029 52.3627V400.587C373.029 400.891 372.782 401.136 372.48 401.136ZM222.252 400.035H371.928V52.9146H222.254V400.035H222.252Z" fill="#363E44"/>
      <path d="M356.148 78.594C359.357 77.4478 371.933 70.5378 377.464 74.2962C383.033 78.0796 385.111 85.2218 384.826 91.9469C384.806 91.9669 384.784 91.9869 384.766 92.0068C383.912 93.6625 382.384 96.0125 379.707 97.5458C376.655 99.2939 375.037 98.3124 371.531 99.656C365.61 101.926 364.444 107.125 362.431 106.129C361.05 105.445 361.599 102.385 362.825 99.7259C364.087 96.9939 366.049 95.7577 365.757 95.3632C365.435 94.9286 363.602 96.442 361.15 98.1152C360.011 98.8918 358.196 102.952 356.17 102.296C354.555 101.771 355.384 97.0788 358.156 93.9772C360.116 91.7846 362.948 91.405 362.773 90.8956C362.641 90.516 360.96 90.7832 359.579 91.445C357.302 92.5388 355.401 96.6717 353.548 96.0324C351.733 95.4031 352.529 91.3176 354.899 88.3059C356.897 85.7662 360.074 85.4915 359.859 84.8597C359.706 84.4152 358.26 84.2428 355.256 84.7947C352.749 85.2567 348.172 87.7715 347.295 85.2218C346.558 83.0766 351.303 80.3221 356.145 78.5915L356.148 78.594Z" fill="#E2A18D"/>
      <path d="M438.031 84.4002C445.045 87.1147 449.61 94.0995 448.981 101.591C448.569 106.503 445.92 111.461 438.111 114.145C419.101 120.68 378.711 91.867 378.711 91.867L374.308 73.2399C395.844 73.2199 418.719 76.9233 438.028 84.3977L438.031 84.4002Z" fill="#E2A18D"/>
      <path d="M267.14 279.806V401.538C267.14 410.226 292.972 417.271 324.837 417.271C356.702 417.271 382.534 410.226 382.534 401.538V279.806H267.138H267.14Z" fill="#4361EE"/>
      <path d="M324.837 417.823C292.175 417.823 266.588 410.671 266.588 401.541V279.259H383.086V401.541C383.086 410.671 357.501 417.823 324.837 417.823ZM267.69 280.358V401.541C267.69 409.769 293.859 416.722 324.837 416.722C355.816 416.722 381.985 409.769 381.985 401.541V280.358H267.69Z" fill="white"/>
      <path d="M324.837 295.514C356.702 295.514 382.534 288.47 382.534 279.781C382.534 271.092 356.702 264.049 324.837 264.049C292.972 264.049 267.14 271.092 267.14 279.781C267.14 288.47 292.972 295.514 324.837 295.514Z" fill="white"/>
      <path d="M324.837 288.534C349.934 288.534 370.28 284.089 370.28 278.605C370.28 273.121 349.934 268.676 324.837 268.676C299.74 268.676 279.394 273.121 279.394 278.605C279.394 284.089 299.74 288.534 324.837 288.534Z" fill="#4361EE"/>
      <path d="M324.837 336.652C292.175 336.652 266.588 329.5 266.588 320.369C266.588 320.065 266.836 319.82 267.138 319.82C267.44 319.82 267.687 320.067 267.687 320.369C267.687 328.598 293.856 335.55 324.835 335.55C355.813 335.55 381.982 328.598 381.982 320.369C381.982 320.065 382.229 319.82 382.532 319.82C382.834 319.82 383.081 320.067 383.081 320.369C383.081 329.5 357.494 336.652 324.832 336.652H324.837Z" fill="white"/>
      <path d="M324.837 377.237C292.175 377.237 266.588 370.085 266.588 360.955C266.588 360.65 266.836 360.406 267.138 360.406C267.44 360.406 267.687 360.653 267.687 360.955C267.687 369.184 293.856 376.136 324.835 376.136C355.813 376.136 381.982 369.184 381.982 360.955C381.982 360.65 382.229 360.406 382.532 360.406C382.834 360.406 383.081 360.653 383.081 360.955C383.081 370.085 357.494 377.237 324.832 377.237H324.837Z" fill="white"/>
      <path d="M243.771 243.886C243.771 243.886 105.455 239.296 104.149 244.37L63.4407 402.505H286.679L327.387 244.373C328.693 239.298 243.771 243.888 243.771 243.888V243.886Z" fill="white"/>
      <path d="M331.799 227.221H108.561L104.146 244.37H327.384L331.799 227.221Z" fill="#F45C27"/>
      <path d="M119.985 238.508C121.825 236.891 122.301 234.426 121.05 233.003C119.798 231.58 117.293 231.737 115.453 233.355C113.614 234.973 113.138 237.438 114.389 238.861C115.64 240.284 118.146 240.126 119.985 238.508Z" fill="white"/>
      <path d="M132.589 238.507C134.429 236.89 134.905 234.425 133.654 233.002C132.402 231.579 129.897 231.737 128.057 233.354C126.218 234.972 125.742 237.437 126.993 238.86C128.244 240.283 130.75 240.125 132.589 238.507Z" fill="white"/>
      <path d="M145.193 238.51C147.032 236.892 147.509 234.427 146.257 233.004C145.006 231.581 142.5 231.739 140.661 233.357C138.822 234.974 138.345 237.439 139.597 238.862C140.848 240.285 143.354 240.127 145.193 238.51Z" fill="white"/>
      <path d="M237.588 295.434H103.387L104.905 289.538H239.106L237.588 295.434Z" fill="#4361EE"/>
      <path d="M294.752 295.434H246.523L248.041 289.538H296.271L294.752 295.434Z" fill="#4361EE"/>
      <path d="M199.337 314.488H160.634L162.153 308.592H200.855L199.337 314.488Z" fill="#4361EE"/>
      <path d="M285.086 314.488H208.272L209.791 308.592H286.604L285.086 314.488Z" fill="#4361EE"/>
      <path d="M184.626 371.656H145.923L147.441 365.757H186.144L184.626 371.656Z" fill="#4361EE"/>
      <path d="M270.372 371.656H193.561L195.079 365.757H271.89L270.372 371.656Z" fill="#4361EE"/>
      <path d="M261.127 333.545H179.549L181.067 327.647H262.645L261.127 333.545Z" fill="#4361EE"/>
      <path d="M218.111 352.599H174.646L176.162 346.703H219.63L218.111 352.599Z" fill="#4361EE"/>
      <path d="M127.903 279.699C127.735 279.699 127.568 279.622 127.461 279.477L120.034 269.475L132.488 259.571C132.725 259.381 133.072 259.421 133.262 259.658C133.452 259.896 133.412 260.243 133.174 260.433L121.555 269.675L128.347 278.82C128.529 279.065 128.477 279.409 128.232 279.589C128.132 279.662 128.017 279.696 127.905 279.696L127.903 279.699Z" fill="#363E44"/>
      <path d="M143.583 279.699C143.421 279.699 143.261 279.627 143.151 279.492C142.961 279.254 143.001 278.907 143.238 278.718L154.858 269.475L148.066 260.33C147.883 260.085 147.936 259.741 148.18 259.561C148.425 259.379 148.77 259.431 148.95 259.676L156.377 269.677L143.923 279.582C143.82 279.662 143.7 279.702 143.581 279.702L143.583 279.699Z" fill="#363E44"/>
      <path d="M131.798 279.107C131.688 279.107 131.576 279.075 131.479 279.005C131.231 278.827 131.174 278.483 131.351 278.236L144.255 260.2C144.432 259.953 144.777 259.896 145.024 260.073C145.271 260.25 145.329 260.595 145.151 260.842L132.248 278.877C132.14 279.027 131.971 279.107 131.798 279.107Z" fill="#363E44"/>
      <path d="M336.275 310.37V432.102C336.275 440.79 362.106 447.835 393.972 447.835C425.837 447.835 451.668 440.79 451.668 432.102V310.37H336.272H336.275Z" fill="#4361EE"/>
      <path d="M393.974 448.387C361.312 448.387 335.725 441.235 335.725 432.105V309.824H452.223V432.105C452.223 441.235 426.638 448.387 393.974 448.387ZM336.826 310.922V432.105C336.826 440.333 362.995 447.286 393.974 447.286C424.952 447.286 451.121 440.333 451.121 432.105V310.922H336.826Z" fill="white"/>
      <path d="M393.974 326.081C425.839 326.081 451.671 319.037 451.671 310.348C451.671 301.659 425.839 294.615 393.974 294.615C362.109 294.615 336.277 301.659 336.277 310.348C336.277 319.037 362.109 326.081 393.974 326.081Z" fill="white"/>
      <path d="M393.974 319.101C419.071 319.101 439.417 314.655 439.417 309.172C439.417 303.688 419.071 299.243 393.974 299.243C368.877 299.243 348.531 303.688 348.531 309.172C348.531 314.655 368.877 319.101 393.974 319.101Z" fill="#4361EE"/>
      <path d="M393.974 367.216C361.312 367.216 335.725 360.064 335.725 350.934C335.725 350.629 335.972 350.384 336.274 350.384C336.577 350.384 336.824 350.631 336.824 350.934C336.824 359.162 362.993 366.114 393.971 366.114C424.95 366.114 451.119 359.162 451.119 350.934C451.119 350.629 451.366 350.384 451.668 350.384C451.97 350.384 452.218 350.631 452.218 350.934C452.218 360.064 426.631 367.216 393.969 367.216H393.974Z" fill="white"/>
      <path d="M393.974 407.801C361.312 407.801 335.725 400.649 335.725 391.519C335.725 391.214 335.972 390.97 336.274 390.97C336.577 390.97 336.824 391.217 336.824 391.519C336.824 399.748 362.993 406.7 393.971 406.7C424.95 406.7 451.119 399.748 451.119 391.519C451.119 391.214 451.366 390.97 451.668 390.97C451.97 390.97 452.218 391.217 452.218 391.519C452.218 400.649 426.631 407.801 393.969 407.801H393.974Z" fill="white"/>
      <path d="M14.599 428.666C14.599 428.666 11.0903 446.921 20.1903 455.469H185.999C185.999 455.469 205.453 409.017 103.534 412.871L14.599 428.668V428.666Z" fill="#363E44"/>
      <path d="M75.1302 287.156C74.9054 287.156 74.6931 287.016 74.6132 286.794L66.7618 265.252C66.6569 264.968 66.8043 264.65 67.0915 264.546C67.3762 264.441 67.6933 264.588 67.7982 264.875L75.6496 286.417C75.7545 286.701 75.6071 287.018 75.32 287.123C75.2575 287.146 75.1951 287.156 75.1327 287.156H75.1302Z" fill="#363E44"/>
      <path d="M113.176 282.386C111.815 271.378 110.049 265.082 104.695 260.947C97.4982 255.388 87.8563 258.085 86.8549 258.382C86.2156 258.572 76.9557 260.715 73.5045 269.635C70.0957 278.443 78.5689 288.532 84.6223 295.671L78.981 312.813C77.5226 319.351 79.3406 323.836 82.8767 325.197C88.2458 327.262 93.3852 324.577 96.8539 317.558L101.926 305.478C106.151 306.055 109.245 304.782 111.028 302.973C114.222 299.737 114.784 295.354 113.179 282.391L113.176 282.386Z" fill="#E2A18D"/>
      <path d="M97.9653 292.123C97.7755 292.123 97.5882 292.023 97.4883 291.846C97.0588 291.099 96.6792 290.412 96.2871 289.698C95.9675 289.116 95.6353 288.517 95.2583 287.85C95.1084 287.585 95.2008 287.248 95.4655 287.101C95.7302 286.951 96.0649 287.043 96.2147 287.308C96.5943 287.977 96.9264 288.582 97.2486 289.166C97.6382 289.873 98.0127 290.555 98.4398 291.296C98.5921 291.561 98.4997 291.896 98.2375 292.048C98.1501 292.098 98.0577 292.12 97.9628 292.12L97.9653 292.123Z" fill="#363E44"/>
      <path d="M74.1365 266.633C74.1365 266.633 77.9848 257.91 91.2403 255.288C108.916 251.792 111.024 260.637 111.024 262.972C111.021 272.544 105.12 275.801 104.588 275.931C105.575 279.806 106.561 283.682 107.547 287.555C106.069 287.965 104.298 288.554 102.82 288.964C101.994 284.893 100.403 283.305 98.1377 282.888C94.5716 282.231 91.2103 285.473 90.8557 288.969C90.531 292.16 91.6523 295.509 94.684 297.317C91.068 297.372 85.7937 298.296 83.8334 298.144C64.9116 291.573 68.7923 268.177 74.1365 266.636V266.633Z" fill="#363E44"/>
      <path d="M65.8193 286.198C73.0814 284.369 77.4855 276.999 75.6559 269.736C73.8264 262.474 66.4562 258.07 59.194 259.9C51.9318 261.729 47.5278 269.1 49.3573 276.362C51.1868 283.624 58.5571 288.028 65.8193 286.198Z" fill="#363E44"/>
      <path d="M13.8373 360.271C12.6661 360.523 7.61664 372.485 2.55218 385.206C-3.24148 399.755 7.42185 415.578 23.0822 415.69L28.2616 415.728L49.6457 356.772L13.8373 360.273V360.271Z" fill="#E2A18D"/>
      <path d="M31.1284 362.026H5.56641L18.0627 336.866C24.3084 324.29 37.1394 316.339 51.179 316.339H90.2038L31.1259 362.029L31.1284 362.026Z" fill="#F45C27"/>
      <path d="M142.564 329.892C157.006 335.735 170.276 339.094 179.609 345.839C191.163 354.19 195.771 366.694 190.512 372.288C184.995 378.154 163.112 382.437 133.861 369.184C134.54 357.219 135.362 341.873 142.562 329.894L142.564 329.892Z" fill="#E2A18D"/>
      <path d="M167.21 345.754C170.736 337.483 179.581 319.003 187.138 305.288C195.074 290.882 208.891 270.769 211.681 272.739C213.269 273.86 211.953 276.994 207.875 285.133C208.492 284.928 210.392 284.679 211.693 285.96C213.621 287.858 212.642 289.925 212.627 290.145C212.832 290.058 215.052 289.186 216.558 290.237C218.254 291.421 218.663 293.169 218.336 294.757C218.848 294.558 220.813 294.016 222.229 295.07C223.688 296.153 224.422 298.341 223.483 299.987C221.76 303.006 216.471 311.881 214.42 316.516C210.39 325.634 200.421 359.934 191.111 371.571C184.273 360.633 174.045 356.692 167.207 345.754H167.21Z" fill="#E2A18D"/>
      <path d="M202.766 288.894C202.646 288.894 202.526 288.856 202.426 288.777L200.259 287.076C200.019 286.889 199.979 286.541 200.166 286.302C200.353 286.062 200.701 286.022 200.94 286.209L203.105 287.91C203.345 288.097 203.385 288.444 203.198 288.684C203.09 288.822 202.928 288.894 202.763 288.894H202.766Z" fill="#363E44"/>
      <path d="M208.27 293.202C208.15 293.202 208.03 293.164 207.93 293.084L205.765 291.384C205.525 291.196 205.485 290.849 205.672 290.61C205.86 290.37 206.207 290.327 206.447 290.517L208.614 292.218C208.854 292.405 208.894 292.752 208.707 292.992C208.597 293.129 208.437 293.202 208.272 293.202H208.27Z" fill="#363E44"/>
      <path d="M213.921 297.524C213.801 297.524 213.681 297.487 213.581 297.407L211.416 295.706C211.177 295.519 211.134 295.172 211.324 294.932C211.511 294.693 211.858 294.65 212.098 294.84L214.266 296.541C214.505 296.728 214.545 297.075 214.358 297.315C214.251 297.452 214.088 297.524 213.924 297.524H213.921Z" fill="#363E44"/>
      <path d="M218.968 301.553C218.848 301.553 218.728 301.515 218.628 301.435L216.461 299.735C216.221 299.547 216.181 299.2 216.368 298.96C216.556 298.721 216.903 298.681 217.142 298.868L219.308 300.569C219.547 300.756 219.587 301.103 219.4 301.343C219.29 301.48 219.13 301.553 218.965 301.553H218.968Z" fill="#363E44"/>
      <path d="M75.325 316.339C116.994 315.352 151.484 331.138 151.484 331.138L147.494 376.446L129.785 376.775L119.002 413.198L105.899 412.823L97.2086 435.841L5.56885 436.88L22.9823 375.057C22.9823 375.057 31.9874 317.365 75.325 316.339Z" fill="#F45C27"/>
      <path d="M129.96 377.033C129.666 377.033 129.423 376.8 129.411 376.506C129.349 375.022 129.319 373.199 129.289 371.436C129.259 369.676 129.226 367.858 129.166 366.382C129.154 366.077 129.389 365.822 129.693 365.81C130 365.792 130.253 366.032 130.265 366.337C130.327 367.823 130.357 369.651 130.39 371.416C130.42 373.172 130.452 374.99 130.512 376.461C130.525 376.765 130.29 377.02 129.985 377.033C129.978 377.033 129.97 377.033 129.963 377.033H129.96Z" fill="#363E44"/>
      <path d="M26.2363 360.823C26.1864 360.823 26.1339 360.815 26.0815 360.8C25.7893 360.715 25.622 360.411 25.7069 360.118L30.679 343.07C30.7639 342.777 31.071 342.61 31.3607 342.695C31.6529 342.78 31.8202 343.085 31.7353 343.377L26.7632 360.426C26.6933 360.665 26.4736 360.823 26.2338 360.823H26.2363Z" fill="#363E44"/>
      <path d="M51.1815 316.339C51.1815 316.339 55.0573 308.453 65.0064 308.423C74.9555 308.395 111.518 316.177 111.518 316.177L113.176 320.302C113.176 320.302 84.1479 337.433 63.2958 339.901C42.4436 342.368 43.3351 323.159 51.184 316.336L51.1815 316.339Z" fill="#F45C27"/>
      <path d="M72.161 325.284C67.8757 325.284 65.0738 324.465 63.2433 323.421C60.4413 321.82 60.0468 319.755 60.0318 319.668C59.9794 319.368 60.1791 319.083 60.4788 319.031C60.776 318.981 61.0607 319.176 61.1156 319.473C61.1306 319.55 61.4802 321.176 63.8601 322.507C68.1704 324.915 78.1794 325.963 101.389 317.275C101.674 317.168 101.991 317.313 102.098 317.598C102.206 317.882 102.061 318.199 101.776 318.307C87.734 323.561 78.3842 325.287 72.161 325.287V325.284Z" fill="#363E44"/>
      <path d="M450.899 239.293H447.815C447.538 237.79 447.146 236.329 446.644 234.918L449.313 233.377L444.097 224.34L441.422 225.883C440.441 224.734 439.369 223.663 438.221 222.681L439.764 220.007L430.726 214.79L429.185 217.46C427.774 216.96 426.314 216.566 424.81 216.288V213.204H414.374V216.288C412.871 216.566 411.41 216.958 409.999 217.46L408.458 214.79L399.42 220.007L400.964 222.681C399.815 223.663 398.744 224.734 397.762 225.883L395.088 224.34L389.871 233.377L392.541 234.918C392.041 236.329 391.646 237.79 391.369 239.293H388.285V249.729H391.369C391.646 251.233 392.039 252.694 392.541 254.104L389.871 255.645L395.088 264.683L397.762 263.14C398.744 264.288 399.815 265.36 400.964 266.341L399.42 269.016L408.458 274.232L409.999 271.563C411.41 272.062 412.871 272.457 414.374 272.734V275.818H424.81V272.734C426.314 272.457 427.774 272.065 429.185 271.563L430.726 274.232L439.764 269.016L438.221 266.341C439.369 265.36 440.441 264.288 441.422 263.14L444.097 264.683L449.313 255.645L446.644 254.104C447.143 252.694 447.538 251.233 447.815 249.729H450.899V239.293ZM436.877 245.077C436.587 254.132 429.213 261.506 420.16 261.794C410.161 262.113 401.99 253.942 402.31 243.943C402.6 234.888 409.974 227.514 419.027 227.226C429.026 226.907 437.197 235.078 436.877 245.077Z" fill="white"/>
      <path d="M419.593 267.889C406.702 267.889 396.216 257.403 396.216 244.512C396.216 233.035 404.762 223.096 416.095 221.395C416.392 221.348 416.676 221.558 416.721 221.857C416.766 222.157 416.559 222.439 416.259 222.484C405.461 224.105 397.32 233.577 397.32 244.512C397.32 256.797 407.314 266.788 419.596 266.788C429.745 266.788 438.612 259.936 441.16 250.126C441.237 249.832 441.537 249.654 441.831 249.732C442.126 249.809 442.303 250.109 442.226 250.404C439.554 260.7 430.249 267.889 419.596 267.889H419.593Z" fill="#F2E6DC"/>
    </svg>
  )
}

export default FirstBlockImage