import * as React from 'react'

import * as styles from './styles.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.texts}>
        <div className={styles.title}>
          ООО «АЙТИ КО» придерживается современного подхода к командообразованию, так как создать продукт в одиночку невозможно.
        </div>
        <div className={styles.title}>
          В настоящее время в ООО «АЙТИ КО» работают более 45 высокопрофессиональных сотрудников.
        </div>
      </div>
      <div className={styles.contacts}>
        <div className={styles.column}>
          <div className={styles.name}>Контакты</div>
          <div className={styles.text}>
            <a href='mailto:it_ko@bk.ru' target={'_blank'} className={styles.label}>it_ko@bk.ru</a>
            <a href='tel:+79093141404' target={'_blank'} className={styles.label}>+7(909) 314-14-04</a>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>Юридический адрес</div>
          <div className={styles.text}>
            <div className={styles.label}>420133, Республика Татарстан, г. Казань, ул. Гаврилова, д. 1, офис 332</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>Почтовый адрес</div>
          <div className={styles.text}>
            <div className={styles.label}>420133, Республика Татарстан, г. Казань, ул. Гаврилова, д. 1, офис 332</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>ОГРН</div>
          <div className={styles.text}>
            <div className={styles.label}>1201600055139 от 11.08.2020</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.name}>ИНН</div>
          <div className={styles.text}>
            <div className={styles.label}>1657262847</div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer