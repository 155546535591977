// extracted by mini-css-extract-plugin
export var bigCards = "styles-module--bigCards--Kin6U";
export var card = "styles-module--card--dpAXZ";
export var cardName = "styles-module--cardName--BSYMS";
export var cardText = "styles-module--cardText--nj3w1";
export var cardsColumn = "styles-module--cardsColumn--synBq";
export var column = "styles-module--column--iB5fZ";
export var container = "styles-module--container--s3Y9b";
export var content = "styles-module--content--I3nnS";
export var description = "styles-module--description--ffOIH";
export var directionsBlock = "styles-module--directionsBlock--LH232";
export var firstBlock = "styles-module--firstBlock--E4cIw";
export var image = "styles-module--image--z0Ifj";
export var lowCards = "styles-module--lowCards--rEvC5";
export var noCopy = "styles-module--noCopy--Bi7ER";
export var ourProjectsBlock = "styles-module--ourProjectsBlock--Sa6RL";
export var structureBlock = "styles-module--structureBlock--2boMk";
export var texts = "styles-module--texts--4z2-0";
export var wrapper = "styles-module--wrapper--7psm0";