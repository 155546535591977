import * as React from 'react'
import Logo from '../atoms/Logo'
import Link from '../atoms/Link'
import MobileMenu from '../MobileMenu'
import { BurgerIcon } from '../atoms/Icons'

import * as styles from './styles.module.scss'

const Header = () => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false)

  return (
    <div className={styles.header}>
      <Logo />
      <div className={styles.controls}>
        <Link to="#directions" className={styles.link}>Направления</Link>
        <Link to="#structure" className={styles.link}>Структура</Link>
        <Link to="#ourProjects" className={styles.link}>Наши проекты</Link>
        <Link to="#contacts" className={styles.link}>Контакты</Link>
      </div>
      <div className={styles.mobileControls}>
        <button className={styles.burger} onClick={() => setOpenMenu(true)}><BurgerIcon /></button>
      </div>
      <MobileMenu open={openMenu} close={() => setOpenMenu(false)} />
    </div>
  )
}

export default Header